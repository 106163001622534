import React, { FC, ReactElement, useCallback, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Container from 'layout/Container';

import ProductCard from 'components/ProductCard';
import Title from 'common/Title';
import Button from 'common/Button';

import './ProductsList.scss';
import './ShadowProductsList.scss';
import { IPropsProductsList } from './models';
import Helpers from './helpers';

const ProductsList: FC<IPropsProductsList> = ({
  products,
  sorryMessage,
  showMoreCtaButton,
  limitOnPageDesktop,
  limitOnPageMobile,
  isSmallDevice,
  sortingProducts,
  addClassName,
}): ReactElement | null => {
  const [isShowAllItems, setShowAllItems] = useState<boolean>(false);

  const handleShowAllItems = useCallback(() => {
    setShowAllItems((oldValue: boolean) => !oldValue);
  }, []);

  const ctaButton = showMoreCtaButton?.[0]?.properties;
  const limit = isSmallDevice ? limitOnPageMobile : limitOnPageDesktop;
  const [filteredProducts] = Helpers.getFilteredItems(products, sortingProducts);
  const itemsToRender = isShowAllItems ? filteredProducts : filteredProducts.slice(0, limit);

  return (
    <div data-test="ProductsList" className={classNames('products-list', addClassName)}>
      <Container fluid>
        {itemsToRender?.length ? (
          <>
            <div className="product-list-grid">
              {itemsToRender.map((product) => (
                <ProductCard key={product.id} productCardData={product} />
              ))}
            </div>
            {ctaButton && products.length > limit && !isShowAllItems ? (
              <div className="cta-btn-wrapper">
                <Button
                  ariaLabel={ctaButton.ariaLabel}
                  clickHandler={handleShowAllItems}
                  className="cta-btn"
                  textAlign="center"
                >
                  {ctaButton.label}
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <Title dataTestAttr="ProductsListNoResult" className="products-list-no-result" Tag="h3">
            {sorryMessage}
          </Title>
        )}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentSortingProducts on TSortingProducts {
    properties {
      items
    }
  }
`;

export default ProductsList;
