import { UmbracoSortingProducts } from '@shared/types';

import { IProductCardData } from 'components/ProductCard';

const getFilteredItems = (
  products: IProductCardData[],
  sortingProducts?: [UmbracoSortingProducts.IStructure]
): [IProductCardData[], IProductCardData[]] => {
  const sortedItems: IProductCardData[] = [];
  const notSortedItems: IProductCardData[] = [];

  if (!sortingProducts || !sortingProducts?.[0]?.properties?.items) {
    return [products, []];
  }
  const sortedItemsIds = sortingProducts[0].properties.items.split(',');

  sortedItemsIds.forEach((id: string) => {
    const item = products.find((product: IProductCardData) => product.pageId === id);

    if (item) {
      sortedItems.push(item);
    }
  });

  if (products.length > sortedItemsIds.length) {
    products.forEach((product: IProductCardData) => {
      if (sortedItemsIds.includes(product.pageId)) {
        return;
      }
      notSortedItems.push(product);
    });
  }

  return [sortedItems, notSortedItems];
};

export default {
  getFilteredItems,
};
